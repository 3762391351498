<template>
  <div :class="['tab-three', animate && 'animate']">
    <section class="swiper-wrap">
      <swiper ref="mySwiper" :options="swiperOptions">
        <swiper-slide class="slide" v-for="(item, index) in photos" :key="index">
          <img class="photo" :src="item.url" alt="" @click="openPopup(item, index)" />
        </swiper-slide>
      </swiper>
      <div class="swiper-arrow">
        <img class="item" @click="slidePrev" src="../imgs/l-2.png" alt="" />
        <img class="item" @click="slideNext" src="../imgs/r-2.png" alt="" />
      </div>
      <div class="swiper-intro">
        <div class="name-wrap">
          <div class="name">
            {{ photos[activeIndex]["name"] }}
          </div>
          <img class="leaf" src="../imgs/leaf-4.png" alt="" />
        </div>
        <div class="text-wrap">
          <p>{{ photos[activeIndex]["desc"] }}</p>
          <p>{{ photos[activeIndex]["work"] }}</p>
        </div>
        <div class="link">
          <a :href="photos[activeIndex]['link']" target="_blank">{{ photos[activeIndex]["linkTitle"] }}</a>
        </div>
      </div>
    </section>
    <section class="swiper-small">
      <div
        :class="['item', activeIndex == index && 'active']"
        v-for="(item, index) in photos"
        alt=""
        :key="index"
        @click="slideTo(index)"
      >
        <img :src="activeIndex == index ? item.url : item.url_" />
        <p>{{ item.name }}</p>
      </div>
    </section>
    <section class="student" @click="openPopup_">
      <img src="../imgs/student.png" alt="" />
      <p>毕业生采访</p>
    </section>
    <div class="line"></div>
    <transition name="bounce">
      <Popup :visible.sync="visible" class="popup" v-if="visible">
        <div class="left"><img :src="photos[activeIndex]['url']" alt="" /></div>
        <div class="right">
          <div class="name">{{ photos[activeIndex]["name"] }}</div>
          <div class="desc" v-html="photos[activeIndex]['detail']"></div>
          <div class="link">
            <a :href="photos[activeIndex]['link']" target="_blank">{{ photos[activeIndex]["linkTitle"] }}</a>
          </div>
        </div>
      </Popup>
    </transition>

    <transition name="bounce">
      <Popup :visible.sync="visible_" class="popup_" v-if="visible_">
        <div class="left" v-if="videos.length > 0">
          <img class="arrow" src="../imgs/arrow-t.png" alt="" @click="handleTop" />
          <img class="arrow" src="../imgs/arrow-b.png" alt="" @click="handlebottom" />
          <div class="tab-wrap">
            <div
              class="img-wrap"
              :style="{
                transform: `translateY(${-100 * parseInt(popup_active / 4)}%)`,
              }"
            >
              <div class="img-item" v-for="(item, index) in videos" :key="index">
                <img @click="popup_active = index" :class="{ active: popup_active == index }" :src="item.imageFile.url" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div class="right" v-if="videos.length > 0">
          <h1>{{ videos[popup_active]["title"] }}</h1>
          <div class="video-box">
            <video
              v-if="visible_"
              controls
              height="100%"
              controlslist="nodownload"
              :src="videos[popup_active]['videoFile']['url']"
            ></video>
          </div>
        </div>
      </Popup>
    </transition>
  </div>
</template>

<script>
import Popup from "./Popup";
export default {
  components: { Popup },
  props: ["videos"],
  data() {
    const _this = this;
    return {
      visible: false,
      visible_: false,
      popup_active: 0,
      activeIndex: 0,
      photos: [
        {
          url: require("../imgs/线下实体班.png"),
          url_: require("../imgs/线下实体班-an.png"),
          name: "线下实体班",
          desc: "1V5最优师生比",
          work: "根据当前在研项目需求针对性培养",
          detail:
            "<p>1V5最优师生比。</p><p>专项指导，对当前一线大厂的工作流程进行拆解。</p><p>贴合在研项目设计理念，根据当前在研项目需求针对性培养。</p>",
          link: "https://appea6xpzir2807.pc.xiaoe-tech.com/",
          linkTitle: "点击查看学生作品",
        },
        {
          url: require("../imgs/网络实战班.png"),
          url_: require("../imgs/网络实战班-an.png"),
          name: "网络实战班",
          desc: "真实的项目经验和规范",
          work: "做出项目需要的动人设计",
          detail:
            "<p>真实的项目经验和规范。</p><p>大厂的研究方向才是目前应该学习的点。</p><p>合理运用脑图，情绪板，做出项目需要的动人设计。</p>",
          link: "https://appea6xpzir2807.pc.xiaoe-tech.com/",
          linkTitle: "点击查看课程",
        },
        {
          url: require("../imgs/网络创作班.png"),
          url_: require("../imgs/网络创作班-an.png"),
          name: "网络创作班",
          desc: "理解UE,合理的运用交互技",
          work: "打造属于自己的视觉语言",
          detail:
            "<p>抄袭？不可以！</p><p>如今的测试题越来越要求独立新颖的UI设计，UI是和原画拼塑造么？</p><p>还是和平面拼构成？理解UE,合理的运用交互技巧，打造属于自己的视觉语言。</p>",
          link: "https://appea6xpzir2807.pc.xiaoe-tech.com/",
          linkTitle: "点击查看课程",
        },
        {
          url: require("../imgs/网络动效班.png"),
          url_: require("../imgs/网络动效班-an.png"),
          name: "网络动效班",
          desc: "0基础手把手教学",
          work: "高效达到行业标准",
          detail:
            "<p>0基础手把手教学,适合转行和无经验同学。</p><p>一线大咖直播授课，教师全部来自大厂。</p><p>通过20+热门项目案例讲解，高效达到行业标准。</p>",
          link: "https://appea6xpzir2807.pc.xiaoe-tech.com/",
          linkTitle: "点击查看课程",
        },
      ],
      swiperOptions: {
        autoplay: {
          delay: 115000,
        },
        allowTouchMove: false,
        speed: 1000,
        pagination: {
          el: ".swiper-pagination",
        },
        on: {
          slideChange: function (a) {
            _this.activeIndex = this.activeIndex;
          },
        },
      },
      animate: false,
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
  methods: {
    slideTo(index) {
      this.swiper.slideTo(index, 1000, false);
    },
    slidePrev() {
      this.swiper.slidePrev();
    },
    slideNext() {
      this.swiper.slideNext();
    },
    handleScroll(e) {
      if (!this.timer) {
        let active = this.popup_active;
        let direction = e.deltaY > 0 ? "down" : "up";
        if (direction == "down") {
          active < this.videos.length - 1 && active++;
        } else {
          active > 0 && active--;
        }
        this.popup_active = active;
        this.timer = setTimeout(() => {
          clearTimeout(this.timer);
          this.timer = null;
        }, 500);
      }
    },
    handleOpenMousewheel() {
      window.addEventListener("mousewheel", this.handleScroll);
    },
    handleCloseMousewheel() {
      window.removeEventListener("mousewheel", this.handleScroll);
    },
    openPopup() {
      this.visible = true;
    },
    openPopup_() {
      this.visible_ = true;
    },
    handleTop() {
      let active = this.popup_active;
      active > 0 && active--;
      this.popup_active = active;
    },
    handlebottom() {
      let active = this.popup_active;
      active < this.videos.length - 1 && active++;
      this.popup_active = active;
    },
  },
  watch: {
    visible_(nv) {
      if (nv) {
        this.handleOpenMousewheel();
      } else {
        this.handleCloseMousewheel();
      }
    },
  },
  mounted() {
    setTimeout(() => {
      this.animate = true;
    }, 22);
  },
};
</script>

<style lang="scss" scoped>
$color: #57bd16;

.tab-three {
  height: 100vh;
  overflow: hidden;
  position: relative;
  .swiper-wrap {
    width: 59vh;
    height: 76vh;
    position: absolute;
    left: 50%;
    top: 5vh;
    transform: translateX(-45%);
    .slide {
      opacity: 0;
      .photo {
        height: 76vh;
        cursor: pointer;
      }
    }
    .swiper-arrow {
      .item {
        opacity: 0;
        position: absolute;
        width: remPc(66);
        cursor: pointer;
        &:nth-of-type(1) {
          bottom: remPc(100);
          left: remPc(-125);
        }
        &:nth-of-type(2) {
          bottom: remPc(200);
          right: remPc(-140);
        }
      }
    }
    .swiper-intro {
      .name-wrap {
        position: absolute;
        overflow: hidden;
        top: 16vh;
        left: remPc(-206);
        color: #fff;
        width: remPc(236);
        height: remPc(134);
        background: url("../imgs/sign.png") left top/ remPc(189) no-repeat;
        margin-bottom: remPc(50);
        transform: translateX(-100px);
        transform-origin: -100% 90%;
        opacity: 0;
        .name {
          font-size: remPc(40);
          font-weight: bold;
          transform: rotate(-10deg) translateY(remPc(20));
        }
        .leaf {
          width: remPc(200);
          transform: rotate(-10deg) translate(remPc(100), remPc(-15));
        }
      }
      .text-wrap {
        position: absolute;
        top: 30vh;
        left: remPc(20);
        transform: translate(-100%, 30px) rotate(-10deg);
        opacity: 0;
        font-weight: bold;
        p {
          color: #888;
          font-size: remPc(20);
          text-align: right;
          letter-spacing: 4px;
          margin-bottom: 6px;
        }
      }
      .link {
        position: absolute;
        top: 36vh;
        left: remPc(20);
        transform: translate(-140%, 50px) rotate(-10deg);
        opacity: 0;
        a {
          font-size: remPc(20);
          color: #40a0ffa7;
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
  .swiper-small {
    position: absolute;
    left: remPc(80);
    z-index: 10;
    bottom: 2vh;
    cursor: pointer;
    .item {
      margin-right: remPc(35);
      float: left;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      position: relative;
      z-index: 12;
      img {
        width: remPc(116);
      }
      p {
        color: #fff;
        margin-top: 5px;
        font-size: remPc(18);
        opacity: 0.5;
      }
      &:nth-of-type(1) {
        transform: translateY(120%);
        p {
          transform: rotate(-5deg);
        }
      }
      &:nth-of-type(2) {
        transform: translateY(120%);
      }
      &:nth-of-type(3) {
        transform: translateY(120%);
        p {
          transform: rotate(3deg);
        }
      }
      &:nth-of-type(4) {
        transform: translateY(120%);
        p {
          transform: rotate(-5deg);
        }
      }
      &.active {
        p {
          opacity: 1;
        }
      }
    }
  }
  .student {
    width: remPc(119);
    color: #9a9a9a;
    position: absolute;
    right: remPc(50);
    bottom: 30vh;
    transform: translateX(200%);
    cursor: pointer;
    img {
      width: 100%;
    }
    p {
      font-size: remPc(18);
      font-weight: 600;
      margin-top: 4px;
      text-align: center;
    }
  }
  .line {
    width: 70vw;
    height: 2px;
    position: absolute;
    transform: rotate(5deg);
    bottom: 50px;
    left: 0;
    z-index: 9;
    &::after {
      content: "";
      position: absolute;
      z-index: 9;
      width: 100%;
      height: 100%;
      background: $color;
      left: 0;
      top: 0;
      transform: translateX(-110%);
    }
  }
  &.animate {
    .swiper-wrap {
      .slide {
        opacity: 1;
        transition: opacity 2s 0.3s;
      }
      .swiper-arrow {
        .item {
          opacity: 1;
          &:nth-of-type(1) {
            transition: opacity 1s 0.5s;
          }
          &:nth-of-type(2) {
            transition: opacity 1s 0.7s;
          }
        }
      }
      .swiper-intro {
        .name-wrap {
          transform: translateX(0);
          opacity: 1;
          transition: transform 0.5s 0.7s, opacity 1s 0.7s;
        }
        .text-wrap {
          opacity: 1;
          transform: translate(-100%, 0px) rotate(-10deg);
          transition: transform 0.5s 1s, opacity 0.5s 1s;
        }
        .link {
          opacity: 1;
          transform: translate(-140%, 30px) rotate(-10deg);
          transition: transform 0.5s 1.2s, opacity 0.5s 1.2s;
        }
      }
    }
    .swiper-small {
      .item {
        transform: translateY(0%);
        &:nth-of-type(1) {
          transition: transform 0.6s 1s;
        }
        &:nth-of-type(2) {
          transition: transform 0.6s 0.9s;
        }
        &:nth-of-type(3) {
          transition: transform 0.6s 0.8s;
        }
        &:nth-of-type(4) {
          transition: transform 0.6s 0.6s;
        }
      }
    }
    .student {
      transform: translateX(0);
      transition: transform 1s 1s;
      img {
        animation-name: upAnimation;
        transform-origin: center bottom;
        animation-duration: 2.5s;
        animation-fill-mode: both;
        animation-iteration-count: infinite;
        animation-delay: 4s;
      }
    }
    .line {
      &::after {
        transition: transform 0.5s;
        transform: translateX(0);
      }
    }
  }
}
.popup {
  display: flex;
  align-items: center;
  justify-content: center;
  .left {
    padding-right: remPc(100);
    img {
      height: 80vh;
    }
  }
  .right {
    color: #fff;
    padding-bottom: remPc(100);

    .name {
      font-size: remPc(50);
      margin-bottom: remPc(100);
      font-weight: bold;
      transform: rotate(-5deg);
    }
    .desc {
      ::v-deep p {
        color: #9a9a9a;
        font-size: remPc(20);
        font-weight: 600;
        letter-spacing: 4px;
        margin-bottom: remPc(50);
        &:nth-of-type(2) {
          transform: rotate(1deg) translateX(remPc(-50));
        }
        &:nth-of-type(3) {
          transform: rotate(1deg) translateX(remPc(-50));
        }
      }
    }
    .link {
      a {
        font-size: remPc(20);
        color: #40a0ffa7;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
.popup_ {
  .left {
    height: 100vh;
    overflow: hidden;
    width: remPc(400);
    position: relative;
    padding: 0;
    cursor: pointer;
    .arrow {
      position: absolute;
      width: remPc(86 * 0.7);
      height: remPc(46 * 0.7);
      left: 50%;
      transform: translateX(-50%);
      &:nth-of-type(1) {
        top: 4vh;
      }
      &:nth-of-type(2) {
        bottom: 4vh;
      }
    }
    .tab-wrap {
      height: 80vh;
      margin-top: 10vh;
      overflow: hidden;
      width: remPc(400);
      position: relative;
      .img-wrap {
        height: 100%;
        width: 100%;
        transition: transform 0.5s;
        .img-item {
          height: 25%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          img {
            width: auto;
            height: 80%;

            &.active {
              border: 2px solid #ccc;
              border-radius: 3px;
            }
          }
        }
      }
    }
  }
  .right {
    width: remPc(1000);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 65vh;
    margin-left: remPc(60);
    padding: 0;
    h1 {
      width: 100%;
      text-align: left;
      font-size: remPc(40);
      margin-bottom: 10px;
    }
    .video-box {
      height: 60vh;
      width: 100%;
      text-align: center;
    }
  }
}
</style>
